// appState.js
import { atom } from "recoil";

// Authentication and User States
export const authState = atom({
  key: "authState",
  default: {
    user: null,
    isAuthenticated: false
  }
});

export const isAdminState = atom({
  key: "isAdminState",
  default: false
});

export const userState = atom({
  key: "userState",
  default: null
});

export const handleLogoutState = atom({
  key: "handleLogoutState",
  default: null
});

// UI States
export const subscriptionStatusState = atom({
  key: "subscriptionStatusState",
  default: "free" // Default to "free" or any other default value
});

export const uiState = atom({
  key: "uiState",
  default: {
    isSidebarOpen: false,
    theme: "light"
  }
});

// Profile States
export const profileState = atom({
  key: "profileState",
  default: {
    displayName: "",
    email: "",
    profileImageUrl: ""
  }
});
