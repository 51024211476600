import { closestCorners } from "@dnd-kit/core";

const customCollisionDetection = (args) => {
  const collisions = closestCorners(args);

  if (!collisions || collisions.length === 0) {
    return [];
  }

  return collisions.sort((a, b) => a.distance - b.distance);
};

export default customCollisionDetection;
// working 080724
