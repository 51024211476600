import React, { useState, useEffect } from "react";
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc } from "firebase/firestore";
import { db as firebaseDB } from "../../firebaseConfig";
import EditableTable from "./EditableTable";

const ManageWidgets = () => {
  const [widgets, setWidgets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dataSnapshot = await getDocs(collection(firebaseDB, "managedData"));
        const itemsList = dataSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setWidgets(itemsList.filter((item) => item.type === "widget"));
      } catch (error) {
        console.error("Error fetching widgets: ", error);
        setError("Error fetching widgets");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleAddWidget = async () => {
    const newWidget = {
      title: "New Widget",
      description: "",
      activationCode: "",
      iconCode: "",
      gridX: 0,
      gridY: 0,
      main_id: 0,
      folder_id: 0,
      common_id: 0,
      type: "widget",
      visible: true,
      state: "active"
    };

    try {
      const docRef = await addDoc(collection(firebaseDB, "managedData"), newWidget);
      newWidget.id = docRef.id;
      setWidgets([...widgets, newWidget]);
    } catch (error) {
      console.error("Error adding widget: ", error);
      setError("Error adding widget");
    }
  };

  const handleUpdateWidget = async (id, updatedWidget) => {
    try {
      const itemRef = doc(firebaseDB, "managedData", id);
      await updateDoc(itemRef, updatedWidget);
      setWidgets(widgets.map((widget) => (widget.id === id ? updatedWidget : widget)));
    } catch (error) {
      console.error("Error updating widget: ", error);
      setError("Error updating widget");
    }
  };

  const handleDeleteWidget = async (id) => {
    try {
      const itemRef = doc(firebaseDB, "managedData", id);
      await deleteDoc(itemRef);
      setWidgets(widgets.filter((widget) => widget.id !== id));
    } catch (error) {
      console.error("Error deleting widget: ", error);
      setError("Error deleting widget");
    }
  };

  const widgetColumns = [
    { field: "title", label: "Title", width: "110px", editable: true },
    { field: "description", label: "Description", width: "150px", editable: true },
    { field: "activationCode", label: "Activation Code", width: "150px", editable: true },
    { field: "iconCode", label: "Icon Code", width: "120px", editable: true },
    { field: "gridX", label: "Grid X", width: "40px", type: "number", editable: true },
    { field: "gridY", label: "Grid Y", width: "40px", type: "number", editable: true },
    { field: "main_id", label: "Main ID", width: "60px", type: "number", editable: true },
    { field: "common_id", label: "Common ID", width: "60px", type: "number", editable: true },
    { field: "folder_id", label: "Folder ID", width: "60px", type: "number", editable: true },
    { field: "visible", label: "Visible", width: "40px", type: "checkbox", editable: true },
    { field: "state", label: "State", width: "50px", editable: true },
    { field: "initialLoad", label: "Initial Load", width: "50px", type: "checkbox", editable: true },
    { field: "isPaid", label: "Is Paid", width: "40px", type: "checkbox", editable: true },
    { field: "subscription", label: "Subscription", width: "50px", editable: true }
  ];

  return (
    <div>
      <div className="flexRow">
        <h3>Server Widgets</h3>
        <button className="handle-add-widget" onClick={handleAddWidget}>
          Add New Widget
        </button>
      </div>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div className="admin-edit-widget-table">
          <EditableTable data={widgets} columns={widgetColumns} onUpdate={handleUpdateWidget} onDelete={handleDeleteWidget} />
        </div>
      )}
      {error && <div>{error}</div>}
    </div>
  );
};

export default ManageWidgets;
