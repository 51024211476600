import React from "react";
import "../css/ImageSelector.css";

const ImageSelector = ({ images, selectedImage, setSelectedImage }) => {
  // console.log("ImageSelector images:", images); // Log images received by ImageSelector

  return (
    <div className="image-selector">
      {images.map((image) => (
        <img
          key={image.src} // Assuming 'image.src' is unique
          src={image.src}
          alt={`option ${image.name}`} // You can use 'image.name' here if appropriate
          className={`image-option ${selectedImage === image ? "selected" : ""}`}
          onClick={() => setSelectedImage(image)}
        />
      ))}
    </div>
  );
};

export default ImageSelector;
