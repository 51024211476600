import { doc, setDoc, getDocs, collection } from "firebase/firestore"; // Firestore functions
import db from "../db/db"; // Dexie setup for local storage
import { db as firebaseDB } from "../firebaseConfig"; // Firestore instance

// Utility function to clean the data and remove `undefined` values
function cleanData(item) {
  const cleanedItem = { ...item };

  // Iterate over each key in the object and remove `undefined` fields
  Object.keys(cleanedItem).forEach((key) => {
    if (cleanedItem[key] === undefined) {
      // Set undefined fields to null or delete the key
      cleanedItem[key] = null; // Alternatively, delete cleanedItem[key];
    }
  });

  return cleanedItem;
}

export const SyncService = {
  // Function to sync all items from IndexedDB to Firebase
  async syncAll(user) {
    console.log("Syncing all items for user:", user);

    if (!user) {
      console.log("No user provided, skipping sync.");
      return;
    }

    try {
      // Reference to the user's items collection in Firestore
      const itemsRef = collection(firebaseDB, "users", user.uid, "items");

      // Fetch all local items from IndexedDB
      const localItems = await db.items.toArray();
      console.log(`SyncAll: Fetched ${localItems.length} items from local IndexedDB.`);

      // Sync each item to Firebase after cleaning it
      for (const localItem of localItems) {
        const cleanedItem = cleanData(localItem);
        const itemId = String(localItem.id); // Ensure item ID is a string
        const itemRef = doc(itemsRef, itemId); // Firestore document reference
        await setDoc(itemRef, cleanedItem); // Push the item to Firebase
        console.log(`SyncAll: Synced item ${itemId} to Firebase.`);
      }

      // Optionally update last synced time
      await this.updateLastSynced(user);

      console.log("SyncAll: All items synced successfully to Firebase!");
    } catch (error) {
      console.error("SyncAll failed", error);
    }
  },

  // Function to sync items from Firebase to local IndexedDB
  async syncFromFirebase(user) {
    console.log("Fetching all items from Firebase for user:", user);

    if (!user) {
      console.log("No user provided, skipping Firebase sync.");
      return;
    }

    try {
      // Reference to the user's items collection in Firestore
      const itemsRef = collection(firebaseDB, "users", user.uid, "items");

      // Fetch all documents (items) from Firebase
      const querySnapshot = await getDocs(itemsRef);
      const firebaseItems = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      console.log(`Fetched ${firebaseItems.length} items from Firebase.`);

      // Store the fetched items in local IndexedDB (Dexie)
      for (const item of firebaseItems) {
        const cleanedItem = cleanData(item); // Clean data before storing
        console.log(`Syncing item ${item.id} to local IndexedDB.`);
        await db.items.put(cleanedItem); // Store the item in IndexedDB
      }

      // Optionally update last synced time
      await this.updateLastSynced(user);

      console.log("All items synced from Firebase to local IndexedDB!");
    } catch (error) {
      console.error("Sync from Firebase failed", error);
    }
  },

  // Optional: Function to update the last sync timestamp in Firebase
  async updateLastSynced(user) {
    if (!user) {
      console.log("No user provided, skipping last sync update.");
      return;
    }

    try {
      const lastSyncedRef = doc(firebaseDB, "users", user.uid, "lastSynced");
      const timestamp = new Date();
      await setDoc(lastSyncedRef, { lastSynced: timestamp }, { merge: true });
      console.log("Last sync time updated:", timestamp);
    } catch (error) {
      console.error("Failed to update last synced time:", error);
    }
  },
};
