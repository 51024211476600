import { SyncService } from "../services/SyncService";
import "../css/SyncButton.css";

const SyncButton = ({ user, setSyncStatus, syncAll = false }) => {
  const handleSync = async () => {
    try {
      setSyncStatus && setSyncStatus(syncAll ? "Syncing all items..." : "Syncing from Firebase...");
      if (syncAll) {
        await SyncService.syncAll(user);
        setSyncStatus && setSyncStatus("All items synced successfully!");
      } else {
        await SyncService.syncFromFirebase(user);
        setSyncStatus && setSyncStatus("Items synced from Firebase successfully!");
      }
    } catch (error) {
      console.error("Sync failed", error);
      setSyncStatus && setSyncStatus("Sync failed. Please try again.");
    }
  };

  return (
    <button onClick={handleSync} className="sync-button">
      {syncAll ? "Sync To Cloud" : "Sync from Cloud"}
    </button>
  );
};

export default SyncButton;
