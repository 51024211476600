import React from "react";
import ManageWidgets from "./ManageWidgets";
import ManageButtons from "./ManageButtons";
import CSVUploadItems from "../admin/CSVUploadItems";

const ManageDataPage = () => {
  return (
    <div className="manage-server-data">
      <h2>Manage Server Data</h2>
      <CSVUploadItems />
      <div style={{ marginTop: "20px" }}>
        <ManageWidgets />
      </div>
      <div style={{ marginTop: "20px" }}>
        <ManageButtons />
      </div>
    </div>
  );
};

export default ManageDataPage;
