import React, { useState } from "react";
import { DndContext, useSensor, useSensors, MouseSensor, TouchSensor, KeyboardSensor, DragOverlay } from "@dnd-kit/core";
import { SortableContext, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import db from "../db/db";
import DraggableItem from "./DraggableItem";
import customCollisionDetection from "../utils/collisionDetection"; // Ensure the correct path is used

export const resolveConflicts = (items) => {
  const occupiedPositions = new Set();

  return items.map((item) => {
    // Create a shallow copy of the item to avoid modifying the original object
    let mutableItem = { ...item };
    let key = `${mutableItem.gridX}-${mutableItem.gridY}-${mutableItem.main_id}-${mutableItem.common_id}-${mutableItem.folder_id}`;

    while (occupiedPositions.has(key)) {
      mutableItem.gridX += 1;
      if (mutableItem.gridX >= 9) {
        // assuming gridX max is 8
        mutableItem.gridX = 0;
        mutableItem.gridY += 1;
      }
      if (mutableItem.gridY >= 5) {
        // assuming gridY max is 4
        mutableItem.gridY = 0;
      }
      key = `${mutableItem.gridX}-${mutableItem.gridY}-${mutableItem.main_id}-${mutableItem.common_id}-${mutableItem.folder_id}`;
    }

    occupiedPositions.add(key);
    return mutableItem;
  });
};

const DndProvider = ({ items, setItems, handleNextGridDragOver, handlePreviousGridDragOver, setIsDragging, handleIconClick, children }) => {
  const [activeDragItem, setActiveDragItem] = useState(null);
  const [activeDragData, setActiveDragData] = useState(null);

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 10 } }),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
  );

  const handleDragStart = (event) => {
    const { active } = event;
    setActiveDragItem(active);
    setActiveDragData(active.data.current);
    setIsDragging(true);
  };

  const handleDragOver = (event) => {
    const { over } = event;
    if (over) {
      if (over.id === "prev-grid-button") {
        handlePreviousGridDragOver();
      } else if (over.id === "next-grid-button") {
        handleNextGridDragOver();
      }
    }
  };

  const handleDragEnd = async (event) => {
    //console.log("Drag ended");
    const { active, over } = event;
    //console.log("Active item:", active);
    //console.log("Over item:", over);

    setIsDragging(false);
    if (!over) {
      //console.log("No target to drop over, drag operation canceled.");
      setActiveDragItem(null);
      setActiveDragData(null);
      return;
    }

    const activeCurrent = activeDragData || active.data.current;
    const overCurrent = over.data.current;
    //console.log("Active current data:", activeCurrent);
    //console.log("Over current data:", overCurrent);

    const sourceGridId = activeCurrent.common_id
      ? "common"
      : activeCurrent.main_id
      ? `main-${activeCurrent.main_id}`
      : activeCurrent.folder_id
      ? `folder-${activeCurrent.folder_id}`
      : null;

    const targetGridId =
      overCurrent.folder_id && overCurrent.folder_id !== 0
        ? `folder-${overCurrent.folder_id}`
        : overCurrent.main_id
        ? `main-${overCurrent.main_id}`
        : overCurrent.common_id
        ? "common"
        : null;

    //console.log("Source grid ID:", sourceGridId);
    //console.log("Target grid ID:", targetGridId);

    if (!sourceGridId || !targetGridId) {
      //console.log("Invalid grid ID, operation aborted.");
      setActiveDragItem(null);
      setActiveDragData(null);
      return;
    }

    const isButtonOverButton = activeCurrent.type === "button" && overCurrent.type === "button";
    // const isButtonOverFolder = activeCurrent.type === "button" && overCurrent.type === "folder";
    const isButtonOverMainGrid = activeCurrent.type === "button" && targetGridId.startsWith("main-");
    const isButtonOverFolderGrid = activeCurrent.type === "button" && targetGridId.startsWith("folder-");

    //console.log("isButtonOverButton:", isButtonOverButton);
    //console.log("isButtonOverFolder:", isButtonOverFolder);
    //console.log("isButtonOverMainGrid:", isButtonOverMainGrid);
    //console.log("isButtonOverFolderGrid:", isButtonOverFolderGrid);

    let updatedItems = items.map((item) => ({ ...item }));
    const isItemOverFolder =
      (activeCurrent.type === "button" || activeCurrent.type === "folder" || activeCurrent.type === "widget") && overCurrent.type === "folder";

    if (isButtonOverButton) {
      //console.log("Button over button detected");

      // Normalize IDs to strings for consistent comparison
      const activeId = activeCurrent.id.toString();
      const overId = overCurrent.id.toString().split("-")[1];

      //console.log("Active item ID:", activeId);
      //console.log("Over item ID:", overId);

      if (activeId === overId) {
        //console.log("Attempting to drop button over itself, operation canceled.");
        setActiveDragItem(null);
        setActiveDragData(null);
        return;
      }

      const newFolder = createFolderItem(targetGridId, overCurrent);
      updatedItems.push(newFolder);
      //console.log("New folder created with ID:", newFolder.id);

      const positions = [
        { gridX: 0, gridY: 0 },
        { gridX: 0, gridY: 1 }
      ];
      let positionIndex = 0;

      updatedItems = updatedItems.map((item) => {
        if (item.id.toString() === activeId || item.id.toString() === overId) {
          item.folder_id = newFolder.id;
          item.main_id = 0;
          item.common_id = 0;
          if (positionIndex < positions.length) {
            item.gridX = positions[positionIndex].gridX;
            item.gridY = positions[positionIndex].gridY;
            positionIndex++;
          }
          //console.log(`Item ${item.id} added to folder ${newFolder.id} at position (${item.gridX}, ${item.gridY})`);
        }
        return item;
      });

      setItems(updatedItems);
    } else if (isItemOverFolder) {
      const folderId = overCurrent.id.split("-")[1];
      //console.log("Item over folder detected. Folder ID:", folderId);

      if (!folderId) {
        //console.log("Invalid folder ID, operation aborted.");
        setActiveDragItem(null);
        setActiveDragData(null);
        return;
      }

      const nextPosition = findNextAvailablePosition(updatedItems, folderId);
      //console.log("Next available position in folder:", nextPosition);

      updatedItems = updatedItems.map((item) => {
        if (item.id === activeCurrent.id) {
          item.folder_id = folderId;
          item.main_id = 0;
          item.common_id = 0;
          item.gridX = nextPosition.gridX;
          item.gridY = nextPosition.gridY;
          //console.log(`Item ${item.id} moved to folder ${folderId} at position (${item.gridX}, ${item.gridY})`);
        }
        return item;
      });

      setItems(updatedItems);
    } else if (isButtonOverMainGrid || isButtonOverFolderGrid) {
      //console.log("Button over main or folder grid detected");

      updatedItems = updatedItems.map((item) => {
        if (item.id === activeCurrent.id) {
          const newMainId = targetGridId.startsWith("main-") ? targetGridId.split("-")[1] : 0;
          const newFolderId = targetGridId.startsWith("folder-") ? targetGridId.split("-")[1] : 0;
          item.gridX = overCurrent.gridX;
          item.gridY = overCurrent.gridY;
          item.folder_id = newFolderId || 0;
          item.main_id = newMainId || 0;
          item.common_id = 0;
          //console.log(`Item ${item.id} moved to grid ${targetGridId} at position (${item.gridX}, ${item.gridY})`);
        }
        return item;
      });

      setItems(updatedItems);
    } else {
      //console.log("Button dropped on unhandled area");

      updatedItems = updatedItems.map((item) => {
        if (item.id === activeCurrent.id) {
          if (targetGridId.startsWith("main-")) {
            const newMainId = targetGridId.split("-")[1];
            return { ...item, main_id: newMainId.toString(), common_id: 0, folder_id: 0, gridX: overCurrent.gridX, gridY: overCurrent.gridY };
          } else if (targetGridId === "common") {
            return { ...item, main_id: 0, common_id: 1, folder_id: 0, gridX: overCurrent.gridX, gridY: overCurrent.gridY };
          } else if (targetGridId.startsWith("folder-")) {
            const newFolderId = targetGridId.split("-")[1];
            return { ...item, main_id: 0, common_id: 0, folder_id: newFolderId.toString(), gridX: overCurrent.gridX, gridY: overCurrent.gridY };
          }
        }
        return item;
      });

      setItems(updatedItems);
    }

    // Resolve any conflicts in the grid positions
    updatedItems = resolveConflicts(updatedItems);

    try {
      await db.items.bulkPut(updatedItems);
      //console.log("Updated items saved to database.");
    } catch (error) {
      //console.error("Failed to save updated items:", error);
    }

    setItems(updatedItems);

    setActiveDragItem(null);
    setActiveDragData(null);
    //console.log("Drag operation completed.");
  };

  const createFolderItem = (targetGridId, targetGridData) => {
    const newFolderId = Date.now();
    return {
      id: newFolderId,
      title: `New Folder`,
      type: "folder",
      visible: true,
      main_id: targetGridId.startsWith("main-") ? targetGridId.split("-")[1] : 0,
      common_id: targetGridId === "common" ? 1 : 0,
      folder_id: targetGridId.startsWith("folder-") ? targetGridId.split("-")[1] : 0,
      gridX: targetGridData.gridX,
      gridY: targetGridData.gridY,
      items: []
    };
  };

  const findNextAvailablePosition = (items, folderId) => {
    if (!folderId) {
      return { gridX: 0, gridY: 0 };
    }

    const folderItems = items.filter((item) => item.folder_id?.toString() === folderId.toString());
    const positions = new Set(folderItems.map((item) => `${item.gridX}-${item.gridY}`));

    for (let y = 0; y < 4; y++) {
      for (let x = 0; x < 6; x++) {
        if (!positions.has(`${x}-${y}`)) {
          return { gridX: x, gridY: y };
        }
      }
    }

    return { gridX: 0, gridY: 0 };
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={customCollisionDetection}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={items.map((item) => item.id)}>
        {children}
        <DragOverlay className="drag-overlay">
          {activeDragItem ? (
            <div className="dragging-item-overlay">
              <DraggableItem item={activeDragItem.data.current} onClick={handleIconClick} />
            </div>
          ) : null}
        </DragOverlay>
      </SortableContext>
    </DndContext>
  );
};

export default DndProvider;
// working 8/7/24 253 lines
