import React, { useState, useEffect, useRef } from "react";
import { Modal } from "@mui/material";
import { ChromePicker } from "react-color";
import "../css/AddItemForm.css";
import Switch from "./Switch";
import ImageSelector from "./ImageSelector";
import DropZone from "../widgets/widget-components/DropZone";
import { importAllImages } from "../utils/importImages";
import log from "loglevel";
import closeButton from "../images/app-buttons/close.png";

const EditModal = ({ open, handleClose, item, onSave, onDelete }) => {
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const [iconOption, setIconOption] = useState("defaultIcon");
  const [uploadedIcon, setUploadedIcon] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState("#FFFFFF");
  const [newTabUrl, setNewTabUrl] = useState(false); // Initial state
  const [availableImages, setAvailableImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageData, setImageData] = useState(null);
  const containerRef = useRef(null);
  const [containerHeight, setContainerHeight] = useState(0);

  // Initialize state from the item prop whenever the item or modal open state changes
  useEffect(() => {
    if (item && open) {
      setTitle(item.title);
      setUrl(item.url);
      setIconOption(item.iconOption || "defaultIcon");
      setBackgroundColor(item.backgroundColor || "#FFFFFF");
      setNewTabUrl(!!item.newTab); // Explicitly set based on item.newTab, using double negation to ensure it's a boolean
      setImageData(item.icon || null);

      // console.log("EditModal opened. NewTab state:", item.newTab); // Commented out for production
    }
  }, [item, open]);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        if (containerRef.current) {
          const height = containerRef.current.getBoundingClientRect().height;
          setContainerHeight(height - 280); // Adjust dynamically based on modal content
        }
      }, 0); // Use a delay to ensure the modal is rendered first
    }
  }, [open]);

  useEffect(() => {
    const images = importAllImages(require.context("../images/new-button-images", false, /\.(png|jpe?g|svg)$/));
    const validImages = images.filter((image) => image && image.name && image.src);
    const sortedImages = validImages.sort((a, b) => a.name.localeCompare(b.name));
    setAvailableImages(sortedImages);
  }, []);

  useEffect(() => {
    if (selectedImage) {
      setImageData(selectedImage.src);
    }
  }, [selectedImage]);

  useEffect(() => {
    if (uploadedIcon) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageData(reader.result);
      };
      reader.readAsDataURL(uploadedIcon);
    }
  }, [uploadedIcon]);

  const handleSave = () => {
    const updatedItem = {
      ...item,
      title,
      url,
      newTab: newTabUrl, // Make sure this reflects the latest state
      icon: iconOption !== "backgroundColor" ? imageData : null,
      iconOption,
      backgroundColor: iconOption === "backgroundColor" ? backgroundColor : null
    };

    log.info("Saving updated item:", updatedItem);
    onSave(updatedItem);
    handleClose();
  };

  const handleDropURL = (url) => {
    setUrl(url);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <div className="edit-modal" ref={containerRef}>
        <div className="app-header">
          <h3 className="form-title">Edit Item</h3>
          <button className="btn-close" onClick={handleClose}>
            <img src={closeButton} alt="Close" />
          </button>
        </div>
        <div className="form-group flexRow">
          <input className="input" type="text" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Title" />
          <input className="input" type="text" value={url} onChange={(e) => setUrl(e.target.value)} placeholder="URL" />
          <DropZone onDropURL={handleDropURL} dropZoneText="Drop link here" height="40px" />

          <label htmlFor="newTab">New tab:</label>
          <Switch
            isChecked={newTabUrl} // Bind the switch to the correct state
            onChange={(checked) => {
              setNewTabUrl(checked);
              // console.log("NewTab switch toggled. New state:", checked); // Commented out for production
            }}
          />
        </div>

        <div className="form-group">
          <select className="select" value={iconOption} onChange={(e) => setIconOption(e.target.value)}>
            <option value="defaultIcon">Default Icon</option>
            <option value="backgroundColor">Color</option>
            <option value="uploadedIcon">Upload...</option>
            <option value="none">None</option>
          </select>
        </div>

        {iconOption === "defaultIcon" && (
          <div className="form-group" style={{ maxHeight: `${containerHeight}px`, overflowY: "scroll" }}>
            <ImageSelector images={availableImages} selectedImage={selectedImage} setSelectedImage={setSelectedImage} />
          </div>
        )}

        {iconOption === "backgroundColor" && (
          <div className="form-group color-picker-container">
            <label htmlFor="backgroundColor">Background Color:</label>
            <ChromePicker color={backgroundColor} onChangeComplete={(color) => setBackgroundColor(color.hex)} disableAlpha={true} />
          </div>
        )}
        {iconOption === "uploadedIcon" && (
          <div className="form-group">
            <input type="file" onChange={(e) => setUploadedIcon(e.target.files[0])} />
            {uploadedIcon && <img src={URL.createObjectURL(uploadedIcon)} alt="Icon preview" style={{ width: "50px", height: "50px" }} />}
          </div>
        )}

        <div className="action-buttons">
          <button className="app-button" onClick={handleSave}>
            Save
          </button>
          <button className="app-button delete-dialog" onClick={() => onDelete(item)}>
            Delete
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default EditModal;
