import React from "react";
import { Link } from "react-router-dom";

const MenuItem = ({ path, label, onClick, width, className }) => {
  const style = {
    width: width || "auto",
  };

  return path ? (
    <Link to={path} className={` ${className || ""}`} style={style}>
      {label}
    </Link>
  ) : (
    <button className={` ${className || ""}`} onClick={onClick} style={style}>
      {label}
    </button>
  );
};

export default MenuItem;
