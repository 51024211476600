import React, { useState, useEffect } from "react";
import { useDraggable } from "@dnd-kit/core";
import db from "../db/db"; // Assuming you have a db module to fetch items
import ButtonItem from "./ButtonItem";
import FolderItem from "./FolderItem";
import DynamicIcon from "./DynamicIcon";

const DraggableItem = ({ item, onOpenFolder, onEdit, onClick }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useDraggable({
    id: item.id,
    data: {
      ...item,
      type: item.type,
      folder_id: item.folder_id,
      main_id: item.main_id,
      common_id: item.common_id,
    },
  });

  const [folderItems, setFolderItems] = useState([]);
  const style = {
    transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
    transition,
    cursor: "grab",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  };

  useEffect(() => {
    const fetchFolderItems = async () => {
      if (item.type === "folder") {
        const allItems = await db.items.toArray();
        const itemsInFolder = allItems.filter((i) => {
          const isMatchingFolder = i.folder_id?.toString() === item.id.toString();
          const isRelevantType = ["button", "folder", "widget"].includes(i.type);
          return isMatchingFolder && isRelevantType;
        });
        setFolderItems(itemsInFolder.slice(0, 6)); // Limit to 6 items
      }
    };

    fetchFolderItems();
  }, [item]);

  const handleClick = () => {
    console.log("Item clicked:", item);
    if (item.type === "folder" && onOpenFolder) {
      onOpenFolder(item);
    } else if (item.type === "widget" && onClick) {
      onClick(item.id);
    } else if (item.type === "button") {
      console.log("Button clicked:", item.id);
      if (item.url) {
        if (item.newTab) {
          // Open URL in a new tab with enhanced security
          const newWindow = window.open(item.url, "_blank", "noopener,noreferrer");
          if (newWindow) newWindow.opener = null; // Ensure security
        } else {
          // Direct navigation in the same tab
          console.log("Navigating to:", item.url, "in the same tab - draggable item");
          window.location.href = item.url;
        }
      }
    }
  };

  const handleContextMenu = (event) => {
    event.preventDefault(); // Prevent default context menu
    event.stopPropagation(); // Stop the event from bubbling up to the document-level listener
    onEdit(item); // Directly call onEdit to open the edit dialog
  };

  // Conditionally add event handlers
  const eventHandlers = {
    onClick: handleClick,
    // Only add onContextMenu if the item is a button
    ...(item.type === "button" && { onContextMenu: handleContextMenu }),
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className={`grid-item ${item.type === "folder" ? "folder-item" : ""}`}
      {...eventHandlers} // Apply event handlers conditionally
    >
      {item.type === "button" ? (
        <ButtonItem item={item} />
      ) : item.type === "folder" ? (
        <FolderItem item={item} folderItems={folderItems} />
      ) : item.type === "widget" ? (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <DynamicIcon iconCode={item.iconCode} />
          <div className="widget-item-title">{item.title}</div>
        </div>
      ) : (
        <div className="button-title">{item.title}</div>
      )}
    </div>
  );
};

export default DraggableItem;
