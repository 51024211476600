import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import GridLayout from "../grid-components/GridLayout";
import db from "../db/db";
import { backgroundImageUrlState, imageBlurState, imageFadeState, backgroundSourceState } from "../state/state";
import { authState } from "../state/appState";
import RandomImage from "../grid-components/RandomImage";
import chatIcon from "../images/app-buttons/chat-icon.png";
import "../css/MainPage.css";

const MainPage = () => {
  const [backgroundImageUrl, setBackgroundImageUrl] = useRecoilState(backgroundImageUrlState);
  const blur = useRecoilValue(imageBlurState);
  const fade = useRecoilValue(imageFadeState);
  const backgroundSource = useRecoilValue(backgroundSourceState);
  const [, setReceiverId] = useState("");
  const [, setIsChatVisible] = useState(false);
  const [auth] = useRecoilState(authState);
  const [loading, setLoading] = useState(true);

  // BroadcastChannel setup
  useEffect(() => {
    const channel = new BroadcastChannel("myhomepage-channel");

    channel.onmessage = (event) => {
      if (event.data.type === "manual-sync") {
        // Handle the sync message and update your local state if needed
        console.log("Received sync data:", event.data.data);
      }
    };

    return () => {
      channel.close();
    };
  }, []);

  // const syncData = () => {
  //   const channel = new BroadcastChannel("myhomepage-channel");
  //   // Assuming `getLocalDataFromIndexedDB` is a function that fetches your data
  //   getLocalDataFromIndexedDB().then((localData) => {
  //     channel.postMessage({ type: "manual-sync", data: localData });
  //   });
  // };

  // const getLocalDataFromIndexedDB = async () => {
  //   try {
  //     const items = await db.items.toArray(); // This assumes you're using Dexie.js or similar.
  //     return items;
  //   } catch (error) {
  //     console.error("Error fetching data from IndexedDB:", error);
  //     return null;
  //   }
  // };

  useEffect(() => {
    const fetchBackgroundImage = async () => {
      try {
        const imageUrl = await db.backgroundImageSelections.getBackgroundImageUrl();
        setBackgroundImageUrl(imageUrl || "/static/media/bg-artboard-18.7e4b626297accfcc0981.jpg");
      } catch (error) {
        // Handle error
      } finally {
        setLoading(false);
      }
    };

    if (backgroundSource === "standard") {
      fetchBackgroundImage();
    } else {
      setLoading(false);
    }
  }, [setBackgroundImageUrl, backgroundSource]);

  // const toggleChat = () => {
  //   setIsChatVisible(!isChatVisible);
  // };

  const startChatWithUser = (userId) => {
    setReceiverId(userId);
    setIsChatVisible(true);
  };

  if (auth?.loading || loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {backgroundSource === "unsplash" ? (
        <RandomImage />
      ) : (
        <div
          className="main-background-container"
          style={{
            backgroundImage: `url(${backgroundImageUrl || "/static/media/bg-artboard-18.7e4b626297accfcc0981.jpg"})`, // Fallback to default
            backgroundSize: "cover",
            backgroundPosition: "center",
            filter: `blur(${blur}px)`,
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            position: "fixed",
            zIndex: -1
          }}
        />
      )}
      <div
        className="background-overlay"
        style={{
          position: "fixed",
          width: "100%",
          height: "100%",
          opacity: fade,
          top: 0,
          left: 0,
          zIndex: 0,
          backgroundColor: "rgba(255, 255, 255, 0.5)"
        }}
      />

      <GridLayout />

      {/* <button className="btn-sync" onClick={syncData}>
        Sync Now
      </button> */}

      {auth?.user && (
        <>
          <button className="btn-chat" onClick={() => startChatWithUser("otherUserId")}>
            <img src={chatIcon} alt="Chat" className="chat-icon" />
          </button>

          {/* <MovableChat receiverId={receiverId} isVisible={isChatVisible} onClose={toggleChat} /> */}
        </>
      )}
    </>
  );
};

export default MainPage;
