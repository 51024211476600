// NotLoggedInPopup.js
import React from "react";
import PropTypes from "prop-types";
import "../css/NotLoggedInPopup.css";

const NotLoggedInPopup = ({ onClose }) => {
  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h2>Access Restricted</h2>
        <p>You need to log in or register to report an issue.</p>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

NotLoggedInPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default NotLoggedInPopup;
