import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import DraggableItem from "./DraggableItem";
import DroppableContainer from "./DroppableContainer";
import db from "../db/db";
import { itemsState } from "../state/state";

const Folder = ({ folder, onClose, onEdit, isDragging }) => {
  const [title, setTitle] = useState(folder.title);
  const [items, setItems] = useRecoilState(itemsState);
  const [folderItems, setFolderItems] = useState([]);

  useEffect(() => {
    const fetchFolderItems = async () => {
      const allItems = await db.items.toArray();
      const folderItems = allItems.filter((item) => item.folder_id?.toString() === folder.id.toString());
      setFolderItems(folderItems);
    };

    fetchFolderItems();
  }, [folder.id, items]);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleClose = async () => {
    await db.items.update(folder.id, { title });
    const updatedItems = items.map((item) => (item.id === folder.id ? { ...item, title } : item));
    setItems(updatedItems);
    onClose();
  };

  const handleDelete = async () => {
    const folderItemsCount = folderItems.length;
    if (folderItemsCount > 0) {
      alert("Folder cannot be deleted as it contains items.");
      return;
    }
    if (window.confirm("Are you sure you want to delete this folder?")) {
      await db.items.delete(folder.id);
      setItems(items.filter((item) => item.id !== folder.id));
      onClose();
    }
  };

  const renderFolderItems = () => {
    const placeholders = [];

    for (let y = 0; y < 4; y++) {
      for (let x = 0; x < 6; x++) {
        const item = folderItems.find((item) => item.gridX === x && item.gridY === y);
        if (item) {
          placeholders.push(<DraggableItem key={item.id} item={item} onEdit={onEdit} />);
        } else {
          placeholders.push(
            <DroppableContainer
              key={`folder-placeholder-${x}-${y}`}
              id={`folder-placeholder-${x}-${y}`}
              className={`grid-item placeholder ${isDragging ? "dragging" : ""}`}
              gridX={x}
              gridY={y}
              gridId={"folder"}
              main_id={0}
              common_id={0}
              folder_id={folder.id}
            />
          );
        }
      }
    }

    return placeholders;
  };

  return (
    <div className="folder-frame">
      <div className="folder-container">
        <div className="folder-header">
          <input type="text" value={title} onChange={handleTitleChange} onBlur={handleClose} />
          <button onClick={handleClose}>Close</button>
          <button onClick={handleDelete}>Delete</button>
        </div>
        <div className="folder-grid">{renderFolderItems()}</div>
      </div>
    </div>
  );
};

export default Folder;
