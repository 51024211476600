import React, { useState, useEffect, useRef } from "react";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { itemsState, gridsState, currentGridIdState } from "../state/state";
import db from "../db/db";
import "../css/AddItemForm.css";
import Switch from "./Switch";
import ImageSelector from "./ImageSelector";
import DropZone from "../widgets/widget-components/DropZone";
import { importAllImages } from "../utils/importImages";
// import log from "loglevel";
import { ChromePicker } from "react-color";
import closeButton from "../images/app-buttons/close.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { resolveConflicts } from "./DndProvider";

const AddItemForm = ({ showForm, setShowForm, initialUrl, reloadItems }) => {
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const [iconOption, setIconOption] = useState("defaultIcon");
  const [uploadedIcon, setUploadedIcon] = useState(null);
  const [graphicDescription, setGraphicDescription] = useState(""); // New state for graphic description
  const [generatedIcons, setGeneratedIcons] = useState([]);
  const [backgroundColor, setBackgroundColor] = useState("#FFFFFF");
  const [newTabUrl, setNewTabUrl] = useState(true);
  const [selectedGrid, setSelectedGrid] = useState(null);
  const [availableImages, setAvailableImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [, setImageData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [lastRequestTime, setLastRequestTime] = useState(0);
  const containerRef = useRef(null);
  const [containerHeight, setContainerHeight] = useState(0);

  const setItems = useSetRecoilState(itemsState);
  const grids = useRecoilValue(gridsState);
  const currentGridId = useRecoilValue(currentGridIdState);

  useEffect(() => {
    setSelectedGrid(currentGridId);
  }, [currentGridId]);

  useEffect(() => {
    const updateHeight = () => {
      if (containerRef.current) {
        const height = containerRef.current.getBoundingClientRect().height;
        setContainerHeight(height - 280); // Adjust the height dynamically
      }
    };

    updateHeight(); // Initial height calculation

    // Add event listener for window resize
    window.addEventListener("resize", updateHeight);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("resize", updateHeight);
    };
  }, [showForm]);

  useEffect(() => {
    if (showForm) {
      setNewTabUrl(true); // Or set it to the desired default value
    }
  }, [showForm]);

  useEffect(() => {
    const images = importAllImages(require.context("../images/new-button-images", false, /\.(png|jpe?g|svg)$/));
    const validImages = images.filter((image) => image && image.name && image.src);
    const sortedImages = validImages.sort((a, b) => a.name.localeCompare(b.name));
    setAvailableImages(sortedImages);
  }, []);

  useEffect(() => {
    if (selectedImage) {
      setImageData(selectedImage.src);
    }
  }, [selectedImage]);

  useEffect(() => {
    if (uploadedIcon) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageData(reader.result);
      };
      reader.readAsDataURL(uploadedIcon);
    }
  }, [uploadedIcon]);

  useEffect(() => {
    // Reset generated icons when the graphic description changes
    setGeneratedIcons([]);
    setSelectedImage(null);
  }, [graphicDescription]);

  // Update the URL state whenever the initialUrl changes
  useEffect(() => {
    if (initialUrl) {
      setUrl(initialUrl); // Set the URL state with the dropped URL
    }
  }, [initialUrl]);

  const generateIcons = async () => {
    const currentTime = Date.now();

    // Throttle requests to one every 10 seconds
    if (currentTime - lastRequestTime < 10000) {
      alert("Please wait at least 10 seconds before generating new icons.");
      return;
    }

    if (!graphicDescription) {
      alert("Please enter a description for the graphic.");
      return;
    }

    setGeneratedIcons([]);
    setLoading(true); // Start loading

    const prompt = `${graphicDescription}, no text`;

    try {
      const response = await fetch("https://api.openai.com/v1/images/generations", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`
        },
        body: JSON.stringify({
          prompt,
          n: 4,
          size: "256x256" // Reduced size for faster processing
        })
      });

      if (!response.ok) {
        if (response.status === 429) {
          throw new Error("You are generating icons too quickly. Please wait a few seconds before trying again.");
        }
        throw new Error(`API error: ${response.statusText}`);
      }

      const data = await response.json();
      //console.log("Generated icons data:", data); // Log the full response data

      setGeneratedIcons(data.data.map((image) => image.url)); // Set the new generated icons
      setLastRequestTime(currentTime); // Update the last request time
    } catch (error) {
      //console.error("Error generating icons:", error);
      if (error.message.includes("429")) {
        alert("Too many requests. Please wait a few seconds before trying again.");
      } else {
        alert(`Failed to generate icons: ${error.message}`);
      }
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleImageSelection = (url) => {
    try {
      setSelectedImage({ src: url });
    } catch (error) {
      //console.error("Error selecting image:", error);
      alert("Failed to load the image. Please try a different one.");
    }
  };

  const handleAddItem = async () => {
    try {
      // Find the next available position in the selected grid
      const position = await findNextAvailablePositionInGrid(
        parseInt(selectedGrid),
        0, // common_id
        0 // folder_id
      );

      const newItem = {
        title,
        url,
        newTab: newTabUrl,
        icon: iconOption !== "backgroundColor" ? selectedImage?.src : null,
        iconOption,
        backgroundColor: iconOption === "backgroundColor" ? backgroundColor : null,
        gridX: position.gridX,
        gridY: position.gridY,
        main_id: parseInt(selectedGrid),
        folder_id: 0,
        common_id: 0,
        type: "button",
        visible: true
      };

      await db.items.add(newItem); // Save the new item to the database

      const updatedItems = await db.items.toArray(); // Fetch updated items

      // Resolve conflicts
      const resolvedItems = resolveConflicts(updatedItems);

      // Update the database with resolved items
      await db.items.bulkPut(resolvedItems);

      // Update the state
      setItems(resolvedItems);

      setShowForm(false); // Close the form
      if (reloadItems) reloadItems(); // Optional: Reload items in the parent component
    } catch (error) {
      //console.error("Failed to add new item:", error);
      alert("Error adding new item: " + error.message);
    }
  };

  const handleDropURL = (url) => {
    setUrl(url);
  };

  const findNextAvailablePositionInGrid = async (main_id, common_id, folder_id) => {
    // Fetch items in the same grid using the compound index
    const gridItems = await db.items.where("[main_id+common_id+folder_id]").equals([main_id, common_id, folder_id]).toArray();

    const positions = new Set(gridItems.map((item) => `${item.gridX}-${item.gridY}`));

    for (let y = 0; y < 5; y++) {
      // assuming gridY max is 4
      for (let x = 0; x < 9; x++) {
        // assuming gridX max is 8
        if (!positions.has(`${x}-${y}`)) {
          return { gridX: x, gridY: y };
        }
      }
    }

    // If no positions are available, return default position
    return { gridX: 0, gridY: 0 };
  };

  return (
    showForm && (
      <div className="add-item-form" ref={containerRef}>
        <div className="app-header">
          <h3 className="form-title">Add New Button</h3>
          <button type="button" onClick={() => setShowForm(false)} className="btn-close">
            <img src={closeButton} alt="Close" />
          </button>
        </div>

        <div className="form-group flexRow">
          <input className="input" type="text" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Title" />
          <input className="input" type="text" value={url} onChange={(e) => setUrl(e.target.value)} placeholder="URL" />
          <DropZone onDropURL={handleDropURL} dropZoneText="Drop link here" height="40px" />

          <label htmlFor="newTab">New tab:</label>
          <Switch isChecked={newTabUrl} onChange={(checked) => setNewTabUrl(checked)} />
        </div>

        <div className="form-group flexRow">
          <label className="noWrap" htmlFor="grid-selection">
            Select Grid:
          </label>
          <select id="grid-selection" className="select" value={selectedGrid} onChange={(e) => setSelectedGrid(parseInt(e.target.value))}>
            {grids.map((grid) => (
              <option key={grid.gridId} value={grid.gridId}>
                {grid.title}
              </option>
            ))}
          </select>

          <select className="select" value={iconOption} onChange={(e) => setIconOption(e.target.value)}>
            <option value="defaultIcon">Default Icon</option>
            <option value="backgroundColor">Background Color</option>
            <option value="uploadedIcon">Upload New Icon</option>
            <option value="generatedIcon">Generate Icon</option>
            <option value="none">None</option>
          </select>
        </div>

        {iconOption === "defaultIcon" && (
          <div className="form-group" style={{ maxHeight: `${containerHeight}px`, overflowY: "scroll" }}>
            <ImageSelector images={availableImages} selectedImage={selectedImage} setSelectedImage={setSelectedImage} />
          </div>
        )}

        {iconOption === "generatedIcon" && (
          <div className="form-group flexRow">
            <input
              className="input"
              type="text"
              value={graphicDescription}
              onChange={(e) => setGraphicDescription(e.target.value)}
              placeholder="Describe the graphic (with no text)"
            />
          </div>
        )}

        {iconOption === "uploadedIcon" && (
          <div className="form-group">
            <input type="file" onChange={(e) => setUploadedIcon(e.target.files[0])} />
            {uploadedIcon && <img src={URL.createObjectURL(uploadedIcon)} alt="Icon preview" style={{ width: "50px", height: "50px" }} />}
          </div>
        )}

        {iconOption === "backgroundColor" && (
          <div className="form-group color-picker-container">
            <label htmlFor="backgroundColor">Background Color:</label>
            <ChromePicker color={backgroundColor} onChangeComplete={(color) => setBackgroundColor(color.hex)} disableAlpha={true} />
          </div>
        )}

        {iconOption === "generatedIcon" && (
          <div className="form-group">
            {!generatedIcons.length && (
              <button type="button" onClick={generateIcons}>
                Generate Icons
              </button>
            )}
            {loading && (
              <div className="loading-spinner">
                <FontAwesomeIcon icon={faSpinner} spin size="2x" style={{ color: "#007bff" }} />
              </div>
            )}
            {!loading && generatedIcons.length > 0 && (
              <div className="generated-icons">
                {generatedIcons.map((icon, index) => (
                  <img
                    key={icon}
                    src={icon}
                    alt={`Generated icon ${index + 1}`}
                    // style={{ width: "120px", height: "120px" }}
                    onClick={() => handleImageSelection(icon)}
                    className={selectedImage && selectedImage.src === icon ? "selected-icon" : ""}
                  />
                ))}
              </div>
            )}
          </div>
        )}
        <div className="action-buttons">
          <button className="app-button" onClick={handleAddItem}>
            Save and add the button
          </button>
        </div>
      </div>
    )
  );
};

export default AddItemForm;
