import React, { useState, useEffect, useRef, useCallback } from "react";
import "../css/ButtonItem.css";

const ButtonItem = ({ item }) => {
  const buttonStyle = item.iconOption === "backgroundColor" ? { backgroundColor: item.backgroundColor } : {};
  const iconStyle = item.iconOption === "backgroundColor" ? { display: "none" } : { display: "block", width: "60px", height: "60px" };

  const [isDragging, setIsDragging] = useState(false);
  const clickTimeout = useRef(null);
  const dragThreshold = 5; // Minimum distance to consider as drag
  const startPosition = useRef({ x: 0, y: 0 });

  const handleClick = useCallback(() => {
    if (!isDragging && item.url) {
      if (item.newTab) {
        window.open(item.url, "_blank");
      } else {
        window.location.href = item.url; // Navigate directly to the URL
      }
    }
  }, [isDragging, item.url, item.newTab]);

  const handleMouseDown = (event) => {
    clickTimeout.current = setTimeout(() => {
      setIsDragging(true);
    }, 200); // 200ms delay to distinguish between click and drag
    startPosition.current = { x: event.clientX, y: event.clientY };
  };

  const handleMouseUp = () => {
    clearTimeout(clickTimeout.current);
    if (!isDragging) {
      handleClick();
    }
    setIsDragging(false);
  };

  const handleMouseMove = (event) => {
    const distance = Math.sqrt(Math.pow(event.clientX - startPosition.current.x, 2) + Math.pow(event.clientY - startPosition.current.y, 2));

    if (distance > dragThreshold) {
      clearTimeout(clickTimeout.current);
      setIsDragging(true);
    }
  };

  useEffect(() => {
    const buttonElement = document.querySelector(".button-item");

    const handleTouchStart = (event) => {
      clickTimeout.current = setTimeout(() => {
        setIsDragging(true);
      }, 200);
      startPosition.current = { x: event.touches[0].clientX, y: event.touches[0].clientY };
    };

    const handleTouchEnd = () => {
      clearTimeout(clickTimeout.current);
      if (!isDragging) {
        handleClick();
      }
      setIsDragging(false);
    };

    const handleTouchMove = (event) => {
      const distance = Math.sqrt(
        Math.pow(event.touches[0].clientX - startPosition.current.x, 2) + Math.pow(event.touches[0].clientY - startPosition.current.y, 2)
      );

      if (distance > dragThreshold) {
        clearTimeout(clickTimeout.current);
        setIsDragging(true);
      }
    };

    if (buttonElement) {
      buttonElement.addEventListener("touchstart", handleTouchStart);
      buttonElement.addEventListener("touchend", handleTouchEnd);
      buttonElement.addEventListener("touchmove", handleTouchMove);
    }

    return () => {
      if (buttonElement) {
        buttonElement.removeEventListener("touchstart", handleTouchStart);
        buttonElement.removeEventListener("touchend", handleTouchEnd);
        buttonElement.removeEventListener("touchmove", handleTouchMove);
      }
    };
  }, [isDragging, handleClick]); // Include 'handleClick' in dependencies

  return (
    <div
      className="button-item"
      style={buttonStyle}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      onClick={(e) => e.preventDefault()} // Prevent default click behavior
    >
      <img src={item.icon || `${process.env.PUBLIC_URL}/svg_icons/default-icon.svg`} alt={item.title} style={iconStyle} className="button-image" />
      <div className="button-title">{item.title}</div>
    </div>
  );
};

export default ButtonItem;
