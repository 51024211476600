import React, { useState } from "react";
import PropTypes from "prop-types";
import "../../css/Dropzone.css";

function DropZone({ width = "110px", height = "100px", borderColor = "#ccc", onDropURL, dropZoneText, className }) {
  const [, setDroppedURL] = useState("");

  const handleDragOver = (e) => {
    e.preventDefault(); // Necessary to allow a drop
  };

  const handleDrop = (e) => {
    e.preventDefault(); // Prevent default behavior

    // Log all available data formats
    //console.log("Available data formats:", e.dataTransfer.types);

    // Retrieve and log data for each available format
    Array.from(e.dataTransfer.types).forEach((type) => {
      //console.log(`Data for ${type}:`, e.dataTransfer.getData(type));
    });

    // Example to get specific data if needed
    const url = e.dataTransfer.getData("text/uri-list");
    //console.log("URL dragged:", url);
    setDroppedURL(url);
    if (onDropURL) {
      onDropURL(url);
    }
  };

  return (
    <div
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      style={{
        width,
        height,
        border: `1px solid ${borderColor}`,
        boxShadow: `inset 0 0 4px gray`
        // lineHeight: height
      }}
      className={className || "drop-zone"}
    >
      {dropZoneText || "Drop Zone"}
      {/* {droppedURL && <p>Link Dropped: {droppedURL}</p>} */}
    </div>
  );
}

DropZone.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  borderColor: PropTypes.string,
  onDropURL: PropTypes.func,
  className: PropTypes.string // Add className to propTypes
};

export default DropZone;
