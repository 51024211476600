import React, { useEffect, useState } from "react";
import MenuItem from "./MenuItem";
import { useRecoilValue } from "recoil";
import { isAdminState, userState, handleLogoutState } from "../state/appState";
import "../css/DropDownMenu.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { collection, getDocs, query } from "firebase/firestore";
import { db as firebaseDB } from "../firebaseConfig";
import db from "../db/db";
import AddItemForm from "./AddItemForm";
import PropTypes from "prop-types";
import SyncButton from "./SyncButton";
import NotLoggedInPopup from "./NotLoggedInPopup";

const DropdownMenu = ({
  currentPath,
  toggleReportIssueModal,
  toggleSettings,
  openEditButtonsModal,
  toggleScreenSizeDisplay,
  reloadItems,
}) => {
  const isAdmin = useRecoilValue(isAdminState);
  const user = useRecoilValue(userState);
  const handleLogout = useRecoilValue(handleLogoutState);
  const [newButtonsAvailable, setNewButtonsAvailable] = useState(false);
  const [showAddItemForm, setShowAddItemForm] = useState(false);
  const [syncStatus, setSyncStatus] = useState("");
  const [showNotLoggedInPopup, setShowNotLoggedInPopup] = useState(false);

  const resolvedPath = currentPath || window.location.pathname;

  const handleReportIssueClick = () => {
    if (!user) {
      // If the user is not logged in, show the popup
      setShowNotLoggedInPopup(true);
    } else {
      console.log("Report Issue clicked");
      toggleReportIssueModal(); // Call the passed function
    }
  };

  const closeNotLoggedInPopup = () => {
    setShowNotLoggedInPopup(false);
  };

  // Checking for new buttons on load
  useEffect(() => {
    const checkForNewButtons = async () => {
      const serverButtonsQuery = query(collection(firebaseDB, "managedData"));
      const querySnapshot = await getDocs(serverButtonsQuery);
      const serverButtons = querySnapshot.docs.map((doc) => doc.id);

      const localButtons = await db.items.where("type").equals("button").toArray();
      const localButtonIds = localButtons.map((button) => button.id);

      const newButtonIds = serverButtons.filter((id) => !localButtonIds.includes(id));
      setNewButtonsAvailable(newButtonIds.length > 0);
    };

    checkForNewButtons();
  }, []);

  const handleUpdateItems = async () => {
    const serverItemsQuery = query(collection(firebaseDB, "managedData"));
    const querySnapshot = await getDocs(serverItemsQuery);

    const serverButtonsAndWidgets = querySnapshot.docs
      .map((doc) => ({ id: doc.id, ...doc.data() }))
      .filter(
        (item) => (item.type === "button" || item.type === "widget") && item.initialLoad === true
      );

    const localItems = await db.items.toArray();
    const localItemIds = new Set(localItems.map((item) => item.id));

    const newItems = serverButtonsAndWidgets.filter((item) => !localItemIds.has(item.id));

    if (newItems.length > 0) {
      try {
        const normalizedNewItems = newItems.map((item) => ({
          ...item,
          gridX: item.gridX !== undefined ? Number(item.gridX) : null,
          gridY: item.gridY !== undefined ? Number(item.gridY) : null,
          main_id: item.main_id !== undefined ? Number(item.main_id) : null,
          common_id: item.common_id !== undefined ? Number(item.common_id) : null,
          folder_id: item.folder_id !== undefined ? Number(item.folder_id) : null,
        }));

        await db.items.bulkPut(normalizedNewItems);
        setNewButtonsAvailable(false);
        alert(`${normalizedNewItems.length} new items (buttons and widgets) have been added.`);
        reloadItems();
      } catch (error) {
        console.error("Error adding new items:", error);
        alert("Error adding new items. Please try again.");
      }
    } else {
      alert("No new items to add.");
    }
  };

  const handleDismissUpdate = () => {
    setNewButtonsAvailable(false);
  };

  const handleExternalRedirect = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div>
      <div className="dropdown-menu">
        <FontAwesomeIcon icon={faBars} className="menu-icon" />
        <div className="dropdown-content">
          <div className="menu-section">
            {resolvedPath !== "/" && (
              <MenuItem className="menu-item" path="/" label="Grid" width="60px" />
            )}
            {resolvedPath !== "/home" && (
              <MenuItem
                className="menu-item"
                label="Home"
                onClick={() => handleExternalRedirect("https://www.myhomepage.ai")}
                width="60px"
              />
            )}
            {!user && resolvedPath !== "/register" && (
              <MenuItem className="menu-item" path="/register" label="Register" width="60px" />
            )}
            {!user && resolvedPath !== "/login" && (
              <MenuItem className="menu-item" path="/login" label="Login" width="60px" />
            )}
            {user && (
              <MenuItem className="menu-item" label="Logout" onClick={handleLogout} width="60px" />
            )}
          </div>

          <div className="menu-section">
            {resolvedPath === "/" && (
              <MenuItem
                className="menu-item"
                label="Background..."
                onClick={toggleSettings}
                width="100px"
              />
            )}
          </div>

          <div className="menu-section">
            {resolvedPath === "/" && (
              <MenuItem
                className="menu-item"
                label="Add Button"
                onClick={() => setShowAddItemForm(true)}
                width="80px"
              />
            )}
            {resolvedPath === "/" && (
              <MenuItem
                className="menu-item"
                label="Button Manager"
                onClick={openEditButtonsModal}
                width="120px"
              />
            )}
            {newButtonsAvailable && (
              <div className="update-section">
                <MenuItem
                  className="menu-item"
                  label="Update Buttons"
                  onClick={handleUpdateItems}
                  width="120px"
                />
                <MenuItem
                  className="menu-item"
                  label="Dismiss Update"
                  onClick={handleDismissUpdate}
                  width="120px"
                />
              </div>
            )}
          </div>

          <div className="menu-section">
            <SyncButton user={user} setSyncStatus={setSyncStatus} syncAll={false} />
            <SyncButton user={user} setSyncStatus={setSyncStatus} syncAll={true} />{" "}
          </div>

          <div className="menu-section">
            <MenuItem
              label="Report Issue"
              className="report-issue"
              width="120px"
              onClick={handleReportIssueClick}
            />
          </div>

          {isAdmin && (
            <div className="admin-section">
              <MenuItem path="/admin" className="menu-item" label="Admin" width="60px" />
              <MenuItem
                label="Screen Size"
                className="menu-item"
                onClick={toggleScreenSizeDisplay}
                width="100px"
              />
              <MenuItem path="/issue-reports" className="menu-item" label="Reports" width="120px" />
            </div>
          )}
        </div>

        {/* AddItemForm */}
        {showAddItemForm && (
          <AddItemForm
            showForm={showAddItemForm}
            setShowForm={setShowAddItemForm}
            initialUrl=""
            reloadItems={reloadItems}
          />
        )}
      </div>

      {/* Display sync status */}
      {syncStatus && <div className="sync-status">{syncStatus}</div>}

      {/* Not logged in popup */}
      {showNotLoggedInPopup && <NotLoggedInPopup onClose={closeNotLoggedInPopup} />}
    </div>
  );
};

// Prop validation
DropdownMenu.propTypes = {
  currentPath: PropTypes.string.isRequired,
  toggleReportIssueModal: PropTypes.func,
  toggleSettings: PropTypes.func,
  openEditButtonsModal: PropTypes.func,
  toggleScreenSizeDisplay: PropTypes.func,
  reloadItems: PropTypes.func,
};

export default DropdownMenu;
