import React from "react";
import Papa from "papaparse";
import { initialItems } from "../InitializeData"; // Adjust the import path based on your project structure

const DownloadCSVButton = () => {
  const handleDownload = () => {
    // Convert the initialItems data to CSV format, including all fields
    const csv = Papa.unparse(initialItems, {
      header: true, // Include headers in the CSV file
      columns: [
        "id",
        "title",
        "description",
        "activationCode",
        "iconCode",
        "allgrids",
        "gridX",
        "gridY",
        "main_id",
        "folder_id",
        "common_id",
        "url",
        "newTab",
        "icon",
        "iconFile",
        "iconOption",
        "svg",
        "backgroundColor",
        "style",
        "visible",
        "state",
        "type",
        "icon_code",
        "icon_file_name",
        "category",
        "width",
        "height",
        "screenX",
        "screenY",
        "start_code",
        "initialLoad",
        "isPaid"
      ]
    });

    // Create a Blob from the CSV string
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    // Create a link to trigger the download
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "initial_items.csv");

    // Trigger the download
    link.click();

    // Clean up by revoking the object URL
    URL.revokeObjectURL(url);
  };

  return <button onClick={handleDownload}>Download Initial Items as CSV</button>;
};

export default DownloadCSVButton;
