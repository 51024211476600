import React, { useState, useEffect } from "react";
import { collection, getDocs, doc, updateDoc, deleteDoc } from "firebase/firestore";
import { db as firebaseDB } from "../firebaseConfig";
import db from "../db/db"; // Import the IndexedDB instance
import AdminItemsPage from "../db/admin/AdminItemsPage";
import ManageDataPage from "../db/firebaseAdmin/ManagedDataPage";
import DownloadCSVButton from "../db/admin/DownloadCSVButton";
import "../css/AdminPage.css";

const AdminPage = () => {
  const [users, setUsers] = useState([]);
  const [buttonItems, setButtonItems] = useState([]);
  const [widgetItems, setWidgetItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch users from Firebase
        const usersSnapshot = await getDocs(collection(firebaseDB, "users"));
        const usersList = usersSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

        // Fetch button and widget items from IndexedDB
        const buttonItemsList = await db.items.where("type").equals("button").toArray();
        const widgetItemsList = await db.items.where("type").equals("widget").toArray();

        setUsers(usersList);
        setButtonItems(buttonItemsList);
        setWidgetItems(widgetItemsList);
      } catch (error) {
        console.error("Error fetching data: ", error);
        setError("Error fetching data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Function to handle role changes
  const handleRoleChange = async (id, newRole) => {
    try {
      await updateDoc(doc(firebaseDB, "users", id), { role: newRole });
      setUsers(users.map((user) => (user.id === id ? { ...user, role: newRole } : user)));
    } catch (error) {
      console.error("Error updating user role: ", error);
      setError("Error updating user role");
    }
  };

  // Function to handle permissions like chat access, weather widget, or paying client status
  const handlePermissionChange = async (id, permission, value) => {
    try {
      await updateDoc(doc(firebaseDB, "users", id), { [permission]: value });
      setUsers(users.map((user) => (user.id === id ? { ...user, [permission]: value } : user)));
    } catch (error) {
      console.error(`Error updating ${permission}: `, error);
      setError(`Error updating ${permission}`);
    }
  };

  // Function to delete user
  const handleDeleteUser = async (id) => {
    try {
      await deleteDoc(doc(firebaseDB, "users", id));
      setUsers(users.filter((user) => user.id !== id));
    } catch (error) {
      console.error("Error deleting user: ", error);
      setError("Error deleting user");
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="admin-page">
      <div className="admin-header">
        <h2>Admin - Manage initial data</h2>
      </div>

      <div className="admin-header">
        <h2>Admin - Manage Users</h2>
      </div>
      <div className="user-table-container">
        <div>
          <table className="table">
            <thead>
              <tr>
                <th>Email</th>
                <th>Role</th>
                <th>Chat Access</th>
                <th>Weather Access</th>
                <th>Paying Client</th>
                <th>Phone</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.id}>
                  <td>{user.email}</td>
                  <td>
                    <select value={user.role} onChange={(e) => handleRoleChange(user.id, e.target.value)}>
                      <option value="user">User</option>
                      <option value="admin">Admin</option>
                    </select>
                  </td>
                  <td>
                    <input type="checkbox" checked={user.chatAccess} onChange={(e) => handlePermissionChange(user.id, "chatAccess", e.target.checked)} />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      checked={user.canAccessWeatherWidget}
                      onChange={(e) => handlePermissionChange(user.id, "canAccessWeatherWidget", e.target.checked)}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      checked={user.isPayingClient}
                      onChange={(e) => handlePermissionChange(user.id, "isPayingClient", e.target.checked)}
                    />
                  </td>
                  <td>{user.phone || "N/A"}</td>
                  <td>
                    <button className="btn-delete" onClick={() => handleDeleteUser(user.id)}>
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <ManageDataPage />
      {/* <AdminItemsPage buttonItems={buttonItems} widgetItems={widgetItems} /> */}
      {/*<DownloadCSVButton />*/}
    </div>
  );
};

export default AdminPage;
