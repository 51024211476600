import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { auth } from "../firebaseConfig";
import { userState } from "./appState";
import { onAuthStateChanged } from "firebase/auth";

function AuthListener() {
  const setUser = useSetRecoilState(userState);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // Store only relevant user data
        setUser({
          uid: user.uid,
          email: user.email,
          displayName: user.displayName
        });
        // console.log("User UID:", user.uid);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, [setUser]);

  return null;
}

export default AuthListener;
