import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { RecoilRoot } from "recoil";
import App from "./App";
import AuthListener from "./state/AuthListener";
import "./index.css";

// Get the root container
const container = document.getElementById("root");

// Create a root and render the React app
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <RecoilRoot>
      <Router>
        <AuthListener />
        <App />
      </Router>
    </RecoilRoot>
  </React.StrictMode>
);

// Unregister old service workers during development
if (process.env.NODE_ENV === "development" && "serviceWorker" in navigator) {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    registrations.forEach((registration) => registration.unregister());
  });
}

// Register the service worker only in production
if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/service-worker.js")
      .then((registration) => {
        console.log("Service worker registered:", registration);
      })
      .catch((error) => {
        console.error("Service worker registration failed:", error);
      });
  });
}
