import axios from "axios";

const grooveAPI = axios.create({
  baseURL: "https://api.groovehq.com/v1",
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_GROOVE_API_KEY}`,
    "Content-Type": "application/json"
  }
});

export const sendWelcomeEmail = async (ticketNumber, userEmail) => {
  try {
    const response = await axios.post("https://us-central1-myhomepage-ai.cloudfunctions.net/sendWelcomeEmail", {
      ticketNumber,
      email: userEmail
    });
    return response.data;
  } catch (error) {
    console.error("Error sending welcome email:", error.message);
    throw error;
  }
};

export const findCustomer = async (email) => {
  try {
    const response = await grooveAPI.get(`/customers/${email}`);
    return response.data;
  } catch (error) {
    console.error("Error finding customer:", error.response ? error.response.data : error.message);
    return null;
  }
};

export const updateOrCreateCustomer = async (email, name, additionalData = {}) => {
  try {
    const customerExists = await findCustomer(email);

    if (customerExists) {
      const response = await grooveAPI.put(`/customers/${email}`, {
        name: name,
        ...additionalData
      });
      console.log("Customer updated:", response.data);
    } else {
      console.log("Customer does not exist. You may need to create the customer.");
    }
  } catch (error) {
    console.error("Error updating/creating customer:", error.response ? error.response.data : error.message);
  }
};

// Test function for finding a customer using Firebase Function
export const testFindCustomer = async () => {
  const email = "qcajax@gmail.com"; // Replace with a real email

  try {
    const response = await axios.post("https://us-central1-myhomepage-ai.cloudfunctions.net/findOrCreateCustomer", {
      email: email,
      name: "Test User" // Optionally include the name if creating a new customer
    });

    if (response.data) {
      console.log("Customer found or created:", response.data);
    } else {
      console.log("Customer not found or failed to create.");
    }
  } catch (error) {
    console.error("Error finding or creating customer:", error.message);
  }
};

// Test function for updating or creating a customer using Firebase Function
export const testUpdateOrCreateCustomer = async () => {
  const email = "qcajax@gmail.com"; // Replace with a real email
  const name = "Test User";
  const additionalData = {
    phone_number: "123-456-7890",
    company_name: "Test Company"
  };

  try {
    const response = await axios.post("https://us-central1-myhomepage-ai.cloudfunctions.net/findOrCreateCustomer", {
      email: email,
      name: name,
      ...additionalData
    });

    if (response.data) {
      console.log("Customer updated or created:", response.data);
    } else {
      console.log("Failed to update or create customer.");
    }
  } catch (error) {
    console.error("Error updating or creating customer:", error.message);
  }
};
