import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { currentGridIdState, gridsState as recoilGridsState } from "../state/state";
import db from "../db/db"; // Make sure to import the db module
import "../css/GridNavigation.css"; // Import the CSS file

const GridNavigation = ({ grids, initialGridId, onGridSelect }) => {
  const [currentGridId, setCurrentGridId] = useRecoilState(currentGridIdState);
  const [editGridId, setEditGridId] = useState(null);
  const [editTitle, setEditTitle] = useState("");
  const [gridsState, setGridsState] = useRecoilState(recoilGridsState);

  useEffect(() => {
    if (initialGridId) {
      setCurrentGridId(initialGridId);
      onGridSelect(initialGridId);
    }
  }, [initialGridId, setCurrentGridId, onGridSelect]);

  const handleRightClick = (event, gridId, title) => {
    event.preventDefault();
    setEditGridId(gridId);
    setEditTitle(title);
  };

  const handleTitleChange = (event) => {
    setEditTitle(event.target.value);
  };

  const handleTitleSubmit = async (gridId) => {
    const updatedGrids = gridsState.map((grid) =>
      grid.gridId === gridId ? { ...grid, title: editTitle } : grid
    );

    setGridsState(updatedGrids);

    // Update the title in the database
    await db.desktopGrids.update(gridId, { title: editTitle });

    setEditGridId(null);
    setEditTitle("");
  };

  return (
    <div className="grid-navigation-container">
      <div className="grid-navigation-buttons">
        {grids.map((grid, index) => (
          <div key={`${grid.gridId}-${index}`}>
            {editGridId === grid.gridId ? (
              <input
                type="text"
                value={editTitle}
                onChange={handleTitleChange}
                onBlur={() => handleTitleSubmit(grid.gridId)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleTitleSubmit(grid.gridId);
                  }
                }}
                className="edit-input" // Apply the new class
                autoFocus
              />
            ) : (
              <button
                onClick={() => {
                  onGridSelect(grid.gridId);
                  setCurrentGridId(grid.gridId);
                  console.log(`Selected grid: ${grid.gridId}`);
                }}
                onContextMenu={(e) => handleRightClick(e, grid.gridId, grid.title)}
                className={`navbutton ${
                  grid.gridId.toString() === currentGridId.toString() ? "active" : "inactive"
                }`}
              >
                {grid.title}
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default GridNavigation;
