// utils/importImages.js
export function importAllImages(r) {
  const images = r.keys().map((fileName) => {
    const src = r(fileName);
    const name = fileName.replace("./", "").replace(/\.[^/.]+$/, ""); // Remove path and extension
    return { src, name };
  });
  // console.log("Imported images:", images); // Log each imported image
  return images;
}
