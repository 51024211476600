import React from "react";
import { useDroppable } from "@dnd-kit/core";

const DroppableContainer = ({ id, children, className, gridX, gridY, gridId, main_id, common_id, folder_id, type, onDragOver, ...props }) => {
  const { isOver, setNodeRef } = useDroppable({
    id,
    data: {
      id,
      gridX,
      gridY,
      gridId,
      main_id,
      common_id,
      folder_id,
      type
    },
    ...props
  });

  return (
    <div ref={setNodeRef} className={`${className} ${isOver ? "is-over" : ""}`} {...props}>
      {children}
    </div>
  );
};

export default DroppableContainer;
