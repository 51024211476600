import Dexie from "dexie";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db as firebaseDB } from "../firebaseConfig";
import { initialDesktopGrids, initialCommonGrids, initialItems } from "./InitializeData";

Dexie.debug = true;

const db = new Dexie("DesktopDB3");

db.version(3).stores({
  desktopGrids: "++gridId, title, sizeX, sizeY, parent, style, visible, state, settings, type",
  items:
    "++id, category, title, description, activationCode, iconOption, iconCode, allgrids, gridX, gridY, main_id, folder_id, common_id, [main_id+common_id+folder_id], url, newTab, icon, iconFile, iconImage, iconSvg, iconColor, style, widgetVisible, visible, state, type, width, height, screenX, screenY, start_code, initialLoad, isPaid, subscription",
  commonGrids: "++id, title, sizeX, sizeY, style, visible, state, settings, type",
  backgroundImageSelections: "++bgImageid, imageUrl",
  settings: "++id, name, value",
  effectPreferences: "++id, name, description, cssClass",
  userEffectPreferences: "++id, effectPreferenceId, effectDuration",
  notes: "++id, content, createdAt, updatedAt, order, pinned, gridD, Xpos, Ypos, width, height, color, style, visible, state, type",
  stickyLinks: "++id, widgetId, title, url, newTab, order",
  weatherData: "++id, city, weatherData, forecastData, timestamp",
  apiAccessCount: "++id, count",
  contacts: "++id, name, email, phone, address, city, state, zip, country, notes",
  messages: "++id, sender, text, timestamp"
});

// Function to fetch an image as a Blob
export const fetchImageAsBlob = async (url) => {
  try {
    const response = await fetch(url, {
      method: "GET",
      mode: "no-cors", // Bypass CORS issues
      cache: "no-store" // Avoid caching issues
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch image: ${response.statusText}`);
    }

    const blob = await response.blob();
    return blob;
  } catch (error) {
    console.error("Error in fetchImageAsBlob:", error);
    throw error;
  }
};

// Function to store an image in IndexedDB
export const storeImageInIndexedDB = async (name, url) => {
  try {
    const blob = await fetchImageAsBlob(url);
    await db.images.add({ name, blob }); // Save the blob to IndexedDB
  } catch (error) {
    console.error("Failed to store image in IndexedDB:", error);
  }
};

// Manually trigger population for testing
// export async function populateInitialData() {
//   await db.delete();
//   await db.open();
//   await db.on("populate").fire();
// }

// Normalize item fields to ensure numeric values
const normalizeItemFields = (item) => {
  return {
    ...item,
    gridX: item.gridX !== undefined ? Number(item.gridX) : null,
    gridY: item.gridY !== undefined ? Number(item.gridY) : null,
    main_id: item.main_id !== undefined ? Number(item.main_id) : null,
    common_id: item.common_id !== undefined ? Number(item.common_id) : null,
    folder_id: item.folder_id !== undefined ? Number(item.folder_id) : null
  };
};

// Fetch initial items from Firebase
const fetchInitialItemsFromFirebase = async () => {
  try {
    const initialItemsQuery = query(collection(firebaseDB, "managedData"), where("initialLoad", "==", true));
    const querySnapshot = await getDocs(initialItemsQuery);
    return querySnapshot.docs.map((doc) => normalizeItemFields({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error("Error fetching items from Firebase:", error);
    throw new Error("Failed to fetch items. Please check your Firebase permissions.");
  }
};

// Populate initial data into Dexie
db.on("populate", async () => {
  try {
    console.log("Populating initial data...");

    // Populate desktopGrids and commonGrids if they don't exist
    if ((await db.desktopGrids.count()) === 0) {
      console.log("Populating desktopGrids...");
      await db.desktopGrids.bulkAdd(initialDesktopGrids);
    }

    if ((await db.commonGrids.count()) === 0) {
      console.log("Populating commonGrids...");
      await db.commonGrids.bulkAdd(initialCommonGrids);
    }

    // Populate items from local data first
    if ((await db.items.count()) === 0) {
      console.log("Populating items from local...");
      const normalizedInitialItems = initialItems.map(normalizeItemFields);
      await db.items.bulkAdd(normalizedInitialItems);
    }

    console.log("STARTUP - Populating backgroundImageSelections...");
    await db.backgroundImageSelections.add({ imageUrl: "/static/media/bg-artboard-18.7e4b626297accfcc0981.jpg" });

    // Fetch and populate additional items from Firebase
    console.log("Populating additional items from Firebase...");
    const firebaseItems = await fetchInitialItemsFromFirebase();
    await db.items.bulkPut(firebaseItems);

    console.log("Database has been populated with initial data.");
  } catch (error) {
    console.error("Error populating database:", error);
  }
});

// Ensure the database is open
export async function ensureDbOpen() {
  if (!db.isOpen()) {
    await db.open();
  }
}

// Other database operations...
export const saveMessage = async (sender, text) => {
  await ensureDbOpen();
  const id = await db.messages.add({ sender, text, timestamp: new Date() });
  return id;
};

export const getAllMessages = async () => {
  await ensureDbOpen();
  return await db.messages.toArray();
};

export const saveUserPreference = async (key, value) => {
  await ensureDbOpen();
  try {
    await db.userEffectPreferences.put({ id: key, value });
  } catch (error) {
    console.error("Error saving user preference:", error);
  }
};

export const getUserPreference = async (key) => {
  await ensureDbOpen();
  try {
    const result = await db.userEffectPreferences.get(key);
    return result ? result.value : null;
  } catch (error) {
    console.error("Error getting user preference:", error);
  }
};

db.backgroundImageSelections.getBackgroundImageUrl = async function () {
  await ensureDbOpen();
  const entry = await this.toCollection().first();
  return entry ? entry.imageUrl : null;
};

db.backgroundImageSelections.setBackgroundImageUrl = async function (imageUrl) {
  await ensureDbOpen();
  await this.clear();
  await this.add({ imageUrl });
};

export const getWeatherData = async (city) => {
  await ensureDbOpen();
  return db.weatherData.where({ city }).first();
};

export const saveWeatherData = async (data) => {
  await ensureDbOpen();
  return db.weatherData.put(data);
};

export const incrementApiAccessCount = async () => {
  await ensureDbOpen();
  const record = await db.apiAccessCount.get(1);
  if (record) {
    record.count += 1;
    await db.apiAccessCount.put(record);
  } else {
    await db.apiAccessCount.add({ id: 1, count: 1 });
  }
};

export const clearOldWeatherData = async () => {
  await ensureDbOpen();
  const now = new Date().getTime();
  const oneDayAgo = now - 24 * 60 * 60 * 1000;

  try {
    await db.transaction("rw", db.weatherData, async () => {
      const oldEntries = await db.weatherData.where("timestamp").below(oneDayAgo).toArray();
      for (const entry of oldEntries) {
        await db.weatherData.delete(entry.id);
      }
    });
    console.log("Old weather data cleared successfully.");
  } catch (error) {
    console.error("Error clearing old weather data:", error);
  }
};

export default db;
