import React, { createContext, useState, useEffect, useCallback } from "react";
import { getWeatherData, saveWeatherData, clearOldWeatherData, incrementApiAccessCount } from "../db/db";
import db from "../db/db";

const API_KEY = process.env.REACT_APP_WEATHER_API_KEY;

const WeatherContext = createContext();

export const WeatherProvider = ({ children }) => {
  const [weatherData, setWeatherData] = useState(null);
  const [forecastData, setForecastData] = useState(null);
  const [city, setCity] = useState("Ottawa");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [apiAccessCount, setApiAccessCount] = useState(0);

  const fetchWeatherData = useCallback(
    async (searchCity, isRefresh = false) => {
      if (!searchCity) {
        setError("Please open the weather widget and enter a city.");
        return;
      }
      setLoading(true);
      setError(null);
      try {
        const cachedData = await getWeatherData(searchCity);
        const now = new Date().getTime();
        const oneDay = 6 * 60 * 60 * 1000;

        if (cachedData && now - cachedData.timestamp < oneDay && !isRefresh) {
          setWeatherData(cachedData.weatherData);
          setForecastData(cachedData.forecastData);
        } else {
          await incrementApiAccessCount();
          const weatherUrl = `https://api.openweathermap.org/data/2.5/weather?q=${searchCity}&units=metric&appid=${API_KEY}`;
          const weatherResponse = await fetch(weatherUrl);

          if (!weatherResponse.ok) throw new Error("Failed to fetch weather data");
          const weatherData = await weatherResponse.json();

          const forecastUrl = `https://api.openweathermap.org/data/2.5/onecall?lat=${weatherData.coord.lat}&lon=${weatherData.coord.lon}&exclude=minutely,hourly,alerts&units=metric&appid=${API_KEY}`;
          const forecastResponse = await fetch(forecastUrl);
          const forecastData = await forecastResponse.json();

          setWeatherData(weatherData);
          setForecastData(forecastData.daily);

          await saveWeatherData({
            city: searchCity,
            weatherData,
            forecastData: forecastData.daily,
            timestamp: now
          });

          localStorage.setItem("lastFetchTimestamp", now);
        }
        localStorage.setItem("lastCity", searchCity);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    },
    [] // Empty dependencies array
  );

  useEffect(() => {
    const fetchLastCityWeather = async () => {
      const lastCity = localStorage.getItem("lastCity");
      if (lastCity) {
        setCity(lastCity);
        await fetchWeatherData(lastCity);
      } else {
        await fetchWeatherData("Ottawa");
      }
    };

    const fetchApiAccessCount = async () => {
      try {
        const record = await db.apiAccessCount.get(1);
        if (record) {
          setApiAccessCount(record.count);
        } else {
          await db.apiAccessCount.add({ id: 1, count: 0 });
          setApiAccessCount(0);
        }
      } catch (error) {
        console.error("Failed to fetch API access count:", error);
      }
    };

    const fetchData = async () => {
      await fetchLastCityWeather();
      await fetchApiAccessCount();
    };

    fetchData();
  }, [fetchWeatherData]); // Include fetchWeatherData in dependencies

  const handleRefresh = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      clearOldWeatherData();
      fetchWeatherData(city, true);
    },
    [city, fetchWeatherData]
  );

  return (
    <WeatherContext.Provider
      value={{
        weatherData,
        forecastData,
        city,
        setCity,
        loading,
        error,
        apiAccessCount,
        fetchWeatherData,
        handleRefresh
      }}
    >
      {children}
    </WeatherContext.Provider>
  );
};

export const useWeather = () => React.useContext(WeatherContext);
