import React, { useEffect, useState, useCallback } from "react";
import { Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth, db } from "./firebaseConfig";
import HomePage from "./pages/HomePage";
import MainPage from "./pages/MainPage";
import Register from "./Register";
import Login from "./Login";
import SettingsPage from "./pages/SettingsPage";
import AdminPage from "./pages/AdminPage";
import ProfilePage from "./pages/ProfilePage";
import "./App.css";
import { WeatherProvider } from "./widgets/WeatherContext";
import { doc, getDoc } from "firebase/firestore";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { isAdminState, handleLogoutState } from "./state/appState";
import DropdownMenu from "./grid-components/DropDownMenu";
import GridLayout from "./grid-components/GridLayout";
// import ApiTest from "./grid-components/ApiTest";
import AdminIssueReports from "./db/admin/AdminIssueReports";
import ReportIssueFormModal from "./grid-components/ReportIssueFormModal";

const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isWeatherWidgetInstalled, setIsWeatherWidgetInstalled] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const setIsAdmin = useSetRecoilState(isAdminState);
  const setHandleLogout = useSetRecoilState(handleLogoutState);
  const isAdmin = useRecoilValue(isAdminState);
  const [showReportIssueModal, setShowReportIssueModal] = useState(false);

  const toggleReportIssueModal = () => {
    // Define the function to toggle modal
    setShowReportIssueModal((prev) => !prev);
  };

  // Fetch user role and check if the weather widget is installed
  const fetchRoleAndWidgetStatus = useCallback(
    async (currentUser) => {
      try {
        if (currentUser) {
          const userDocRef = doc(db, "users", currentUser.uid);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            const userData = userDoc.data();
            setIsAdmin(userData.role === "admin");
            setIsWeatherWidgetInstalled(userData.isWeatherWidgetInstalled || false); // Set widget status
          }
        } else {
          setIsAdmin(false);
        }
      } catch (error) {
        console.error("Error fetching role or widget status:", error);
      }
    },
    [setIsAdmin]
  );

  // Listen for auth state changes and fetch user data
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
      if (currentUser) {
        fetchRoleAndWidgetStatus(currentUser); // Fetch role and widget status
      }
    });
    return () => unsubscribe();
  }, [fetchRoleAndWidgetStatus]);

  const handleLogout = useCallback(async () => {
    await signOut(auth);
    navigate("/");
  }, [navigate]);

  useEffect(() => {
    setHandleLogout(() => handleLogout);
  }, [setHandleLogout, handleLogout]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <DropdownMenu
        currentPath={location.pathname}
        toggleReportIssueModal={toggleReportIssueModal}
      />

      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/settings" element={user ? <SettingsPage /> : <Navigate to="/login" />} />
        <Route path="/profile" element={user ? <ProfilePage /> : <Navigate to="/login" />} />
        <Route
          path="/grid"
          element={
            user ? (
              isWeatherWidgetInstalled ? (
                <WeatherProvider>
                  <GridLayout user={user} isAdmin={isAdmin} handleLogout={handleLogout} />
                </WeatherProvider>
              ) : (
                <GridLayout user={user} isAdmin={isAdmin} handleLogout={handleLogout} />
              )
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route path="/admin" element={user && isAdmin ? <AdminPage /> : <Navigate to="/login" />} />
        <Route
          path="/issue-reports"
          element={isAdmin ? <AdminIssueReports /> : <Navigate to="/" />}
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>

      {/* Report Issue Modal */}
      <ReportIssueFormModal showModal={showReportIssueModal} onClose={toggleReportIssueModal} />

      {/* <ApiTest /> */}
    </div>
  );
};

export default App;
