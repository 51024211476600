import React from "react";
import default_folder_icon from "../images/app-buttons/folder.svg";
import default_button_icon from "../images/app-buttons/upload.gif";
import default_widget_icon from "../images/app-buttons/widget-icon.svg";

const FolderItem = ({ item, folderItems, handleIconClick }) => {
  const isEmpty = folderItems.length === 0;
  return (
    <div className={`folder-item-container ${isEmpty ? "empty" : "not-empty"}`}>
      <div className="folder-item-content">
        {folderItems.length > 0 ? (
          folderItems.map((folderItem, index) => (
            <img
              key={index}
              src={
                folderItem.type === "folder"
                  ? default_folder_icon
                  : folderItem.type === "widget"
                  ? default_widget_icon
                  : folderItem.icon
                  ? folderItem.icon
                  : default_button_icon
              }
              alt={folderItem.title}
              className="folder-small-item"
              // onClick={() => handleIconClick(folderItem)}
            />
          ))
        ) : (
          <img src={default_folder_icon} alt={item.title} className="empty-folder-icon" />
        )}
      </div>
      <div className="folder-item-title">{item.title}</div>
    </div>
  );
};

export default FolderItem;
